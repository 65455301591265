<template>
  <div class="navigation_page">
    <div v-for="item in routers" :key="item.path">
      <ul class="title">
        <!-- <span>{{ item.name ? item.name : '' }}</span> -->
        <template v-if="item.children">
          <li class="" v-for="menu in item.children" :key="menu.path">
            <div class="row justify-between align-center link">
              <a class="name" @click="goto(item.path + '/' + menu.path)">{{ menu.meta && menu.meta.title ? menu.meta.title : '' }}</a>
              <span>{{ item.path + '/' + menu.path }}</span>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import routers from '@/utils/routers'
export default {
  data() {
    return {
      routers
    }
  },
  created() {
    console.log(this.routers, 'ddddddddddddddd')
  },
  methods: {
    goto(url) {
      this.$router.push({ path: url })
    }
  }
}
</script>
<style scoped>
.navigation_page {
  /* padding: 50px; */
  font-size: 14px;
}

.title {
  border: 1px solid #eee;
  padding: 10px;
}

.link {
  /* line-height: 30px; */
  height: 30px;
}

.name {
  color: blue;
}
</style>
